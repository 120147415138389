import { FC, useMemo } from "react";
import { useTranslation } from "@helpers/useTranslation";
import { useEventId } from "src/helpers/useEvent";
import { useSimpleCollection } from "src/helpers/useSimpleCollection";
import { useTeamId } from "src/helpers/useTeam";
import { TeamRow } from "./TeamRow";
import { sortTeamsByPoints } from "src/helpers/sortTeams";

type Props = {
  compact?: boolean;
  limit?: number;
  className?: string;
};

export const HighscoreTable: FC<Props> = ({ compact, limit, className }) => {
  const { t } = useTranslation("highscores");
  const eventId = useEventId();
  const teamId = useTeamId();
  const [teams] = useSimpleCollection<Team>(`events/${eventId}/teams`);
  const teamsWithoutUploaders = useMemo(() => teams?.filter((team) => !team.isUploader), [teams]);
  const sortedTeams = useMemo(() => teamsWithoutUploaders?.sort(sortTeamsByPoints), [teamsWithoutUploaders]);
  const limitedTeams = useMemo(() => {
    if (!limit) return sortedTeams;
    return sortedTeams?.slice(0, limit);
  }, [sortedTeams]);

  return (
    <div className={`highscore-table ${className ?? ""}`}>
      <table className="table w-full">
        <thead>
          <tr>
            <th className="pl-4 pr-2">#</th>
            <th className="pr-2 pl-2">{t("team")}</th>
            <th className="pr-2 pl-2"></th>
            <th className="pr-4 pl-2">{t("score")}</th>
          </tr>
        </thead>
        <tbody>
          {limitedTeams.map((team, index) => (
            <>
              {team.id === teamId && (
                <div className="absolute left-0 mt-5 -ml-5 animate-bounce-horizontal-inertia text-neutral">❯</div>
              )}
              <TeamRow
                compact={compact}
                active={team.id === teamId}
                key={team.id}
                index={index + 1}
                teamKey={team.id}
                teamName={team.displayName ?? team.name}
                points={Math.round(team?.points)}
                eventId={eventId}
              />
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

import { ConfirmModal } from "@components/ConfirmModal";
import { FirebaseCachedImage } from "@components/FirebaseCachedImage";
import { DeleteIcon } from "@components/Icons/Delete";
import { Loading } from "@components/Loading";
import { TopNav } from "@components/TopNav";
import { deleteDoc, doc, getDocs, updateDoc, collection } from "@firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "@helpers/useTranslation";
import { FirebaseContext } from "src/helpers/firebase";
import { formatPoints } from "src/helpers/formatPoints";
import { sortTeamsByPoints } from "src/helpers/sortTeams";
import { useEventId } from "src/helpers/useEvent";
import { useSimpleCollection } from "src/helpers/useSimpleCollection";
import { useToast } from "src/helpers/useToast";
import { UpIcon } from "@components/Icons/Up";
import { DownIcon } from "@components/Icons/Down";
import { SortIcon } from "@components/Icons/Sort";

export const ManageTeams = () => {
  const { t } = useTranslation("admin");

  const eventId = useEventId();
  const { firestore } = useContext(FirebaseContext);
  const [teams, tLoading] = useSimpleCollection<Team>(`events/${eventId}/teams`);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [activeTeamIdToDelete, setActiveTeamIdToDelete] = useState<string | undefined>();
  const [sortedTeams, setSortedTeams] = useState<Team[]>([]);
  const [order, setOrder] = useState<string[]>();
  const [firstRender, setFirstRender] = useState(true);

  // We sort the teams by points on first render
  // Later we keep the original order to prevent jankiness when adding/removing points
  // Finally we allow user to sort by points again by clicking a button
  useEffect(() => {
    if (firstRender && teams.length > 0) {
      setFirstRender(false);
      sortByPoints();
      return;
    }
    if (order) sortByOrder();
  }, [teams]);

  const sortByPoints = () => {
    const sortedTeams = [...teams].sort(sortTeamsByPoints);
    setSortedTeams(sortedTeams);
    const newOrder = sortedTeams.map((team) => team.id);
    setOrder(newOrder);
  };

  // Sorts by the original order when first rendered (or when proactively sorted by points)
  const sortByOrder = () => {
    const sortedTeams = order?.map((id) => teams.find((team) => team.id === id)).filter(Boolean) as Team[];
    setSortedTeams(sortedTeams);
  };

  const confirmDeleteTeam = (teamId: string) => {
    setShowConfirmModal(true);
    setActiveTeamIdToDelete(teamId);
  };

  const addPoints = async (teamId: string) => {
    const team = teams.find((team) => team.id === teamId);
    if (!team) return;

    const currentPoints = team.points;
    const additionalPoints = team.additionalPoints || 0;

    const teamDocRef = doc(firestore, `events/${eventId}/teams`, teamId);
    await updateDoc(teamDocRef, {
      additionalPoints: additionalPoints + 10,
      points: currentPoints + 10,
    });
  };

  const removePoints = async (teamId: string) => {
    const team = teams.find((team) => team.id === teamId);
    if (!team) return;

    const currentPoints = team.points;
    const additionalPoints = team.additionalPoints || 0;

    const teamDocRef = doc(firestore, `events/${eventId}/teams`, teamId);

    await updateDoc(teamDocRef, {
      additionalPoints: additionalPoints - 10,
      points: currentPoints - 10,
    });
  };

  const toast = useToast();

  const onDelete = async () => {
    try {
      // Remove all solutions docs (effectively removing the sub-collection) from this team
      const solution = await getDocs(
        collection(firestore, `events/${eventId}/teams/${activeTeamIdToDelete}/solutions`),
      );
      solution.forEach((solution) => deleteDoc(solution.ref));

      // Finally remove the team doc itself
      const teamDocRef = doc(firestore, `events/${eventId}/teams`, activeTeamIdToDelete);
      await deleteDoc(teamDocRef);
      toast(t("deleted"), "👍");
    } catch (error) {
      toast(t("problemOccured"), "", "error");
      console.error(error);
    }

    setActiveTeamIdToDelete(undefined);
  };

  return (
    <>
      <ConfirmModal
        messageCopy={t("areYouSureDeleteTeam")}
        show={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={onDelete}
      />
      <TopNav isAdmin backLink="/admin" />
      <main className="lex-1 flex-col items-center">
        <div className="mb-24 flex flex-col items-center py-4 px-6">
          <Loading loading={tLoading}>
            <div className="flex w-full max-w-lg flex-col ">
              <h1 className="mt-12 mb-8 text-center  text-4xl font-semibold">{t("manageTeams")}</h1>
              <p className="mt-16 mb-8 text-center text-lg font-light">{t("aboutManageTeams")}</p>
              {sortedTeams.length === 0 && <div className="text-center text-xl font-semibold">{t("noTeamsYet")}</div>}
              <div className="mb-4 flex justify-end">
                <button onClick={sortByPoints} className="btn-outline btn-sm btn bg-opacity-90 px-1 text-neutral">
                  <SortIcon className="mr-2 h-3 w-3" />
                  {t("sortByPoints")}
                </button>
              </div>
              {sortedTeams.map((team) => (
                <div
                  className="card card-side mb-8 rounded-lg border border-base-300 bg-base-200 shadow-xl "
                  key={team.id}
                >
                  <div className="avatar ml-4 items-center">
                    <div className="h-24 w-24 rounded-full ring ring-primary ring-offset-2 ring-offset-base-100">
                      <FirebaseCachedImage
                        uri={`events/${eventId}/teams/${team.id}/avatar_thumb`}
                        defaultUri="anonymous.webp"
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="card-body overflow-hidden text-ellipsis py-4">
                    <h2 className="card-title mt-2 max-h-32 overflow-hidden text-ellipsis">
                      {team.displayName ?? team.name}
                    </h2>
                    <div className="flex flex-col">
                      <div className="text-neutral">{`${formatPoints(team?.points)} ${t("points")}`}</div>
                      <div className="mt-2">
                        <button
                          onClick={() => addPoints(team.id)}
                          className="btn-outline btn-sm btn mr-2 bg-opacity-90 px-3  text-neutral"
                        >
                          <UpIcon className="h-3 w-3" />
                        </button>
                        <button
                          onClick={() => removePoints(team.id)}
                          className="btn-outline btn-sm btn bg-opacity-90 px-3  text-neutral"
                        >
                          <DownIcon className="h-3 w-3" />
                        </button>
                      </div>
                      {team.additionalPoints !== 0 && (
                        <div className="mt-2 flex ">
                          <span className="mr-2">{t("extraPoints")}</span>
                          <div
                            className={`font-semibold ${
                              team.additionalPoints > 0 ? "text-lime-600" : "text-orange-600"
                            }`}
                          >
                            {team.additionalPoints > 0 ? "+" : ""}
                            {team.additionalPoints || 0}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="card-actions justify-end">
                      <button
                        onClick={() => confirmDeleteTeam(team.id)}
                        className="btn-error btn-sm btn -mr-4 mt-4 border-white bg-red-600 bg-opacity-90 text-white sm:mt-0"
                      >
                        <DeleteIcon className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Loading>
        </div>
      </main>
    </>
  );
};

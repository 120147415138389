import { useTranslation } from "@helpers/useTranslation";
import { useContext, useEffect, useMemo, useState } from "react";
import { FirebaseContext } from "src/helpers/firebase";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useEventId } from "src/helpers/useEvent";
import { useEventNavigate } from "src/helpers/useEventNavigate";
import { usePersistedState } from "src/helpers/usePersistedState";
import { useSimpleCollection } from "src/helpers/useSimpleCollection";
import { useToast } from "src/helpers/useToast";
import { useHasNoPin } from "src/helpers/useHasNoPin";

export const SelectTeam = () => {
  const { t } = useTranslation("selectteam");

  const { pinAuth, functions } = useContext(FirebaseContext);
  const [teamSelected, setTeamSelected] = useState<Team | undefined>();
  const [appData, setAppData] = usePersistedState();
  const eventId = useEventId();

  const [password, setPassword] = useState("");
  const needsPassword = !!teamSelected?.password;
  const passwordMismatch = teamSelected?.password !== password;

  const toast = useToast();
  const [teams] = useSimpleCollection<Team>(`events/${eventId}/teams`);
  const selectableTeams = useMemo(() => teams?.filter((team) => !team?.isUploader), [teams]);
  const sortedTeams = useMemo(
    () =>
      selectableTeams?.sort((a, b) => {
        if (!a.name || !b.name) return 0;
        return a.name.localeCompare(b.name);
      }),
    [selectableTeams],
  );

  const navigate = useEventNavigate();
  const [exchangePinForToken, _, errorExchangePin] = useHttpsCallable(functions, "exchangePinForToken");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (errorExchangePin) {
      if (errorExchangePin.message.includes("Invalid PIN code")) {
        toast(t("invalidPinCode"), "🤷‍♂️", "error");
      } else {
        toast(t("somethingWentWrong"), "🤷‍♂️", "error");
      }
      console.error(errorExchangePin);
    }
  }, [errorExchangePin]);

  const handleTeamSelect = async () => {
    try {
      setLoading(true);
      const pin = appData?.pin;
      const result = await exchangePinForToken({ pin, eventId, teamName: teamSelected.name.trim() });
      const token = result?.data as string;
      const res = await pinAuth(token);
      const uid = res.user?.uid;
      if (uid) {
        setAppData({ ...appData, uid, isUploader: false });
        await new Promise((resolve) => setTimeout(resolve, 200));
        navigate("/challenges");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useHasNoPin();

  const toSearchableString = (str: string) => str.trim().toLowerCase().replace(/\s/g, "");
  const title = t("title");
  const about = t("about");
  const selectTeam = t("selectTeam");
  const pickExisting = t("pickExisting");
  const passwordCopy = t("password");

  return (
    <main className="flex-1 flex-col">
      <div className="flex flex-col px-6 py-4">
        <div>
          <h1 className="mt-12 mb-8 text-center text-4xl font-semibold">{title}</h1>
          <div className="mt-16 mb-8 text-center text-lg font-normal">{about}</div>
        </div>
        <div className="mx-6 flex justify-center">
          <div className="form-control w-full max-w-xs">
            <label className="label">
              <span className="label-text">{pickExisting}</span>
            </label>
            <select
              className="select-bordered select bg-gray-200"
              onChange={(e) => {
                const teamName = e.target.value;
                const teamSelected = selectableTeams.find(
                  (team) => toSearchableString(team.displayName ?? team.name) === toSearchableString(teamName),
                );
                setTeamSelected(teamSelected);
              }}
            >
              <option disabled selected>
                {selectTeam}
              </option>
              {sortedTeams.map(({ id, name, displayName }) => (
                <option key={id}>{displayName ?? name}</option>
              ))}
            </select>
          </div>
        </div>

        {needsPassword && (
          <div className="mx-6 mt-4 flex justify-center">
            <div className="form-control w-full max-w-xs">
              <label className="label">
                <span className="label-text">{passwordCopy}</span>
              </label>
              <input
                className="input-bordered input"
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
          </div>
        )}

        <div className="mt-12 flex justify-center">
          <button
            className={`btn-primary btn mb-4 px-4 ${loading ? "loading" : ""}`}
            disabled={!teamSelected || loading || (needsPassword && passwordMismatch)}
            onClick={handleTeamSelect}
          >
            {t("play")}
          </button>
        </div>
      </div>
    </main>
  );
};
